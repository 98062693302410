import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { TValue } from '../../../core/interfaces/generic.interface';

type InputType = 'text' | 'number' | 'date';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, FormsModule, NgxMaskDirective],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor, OnInit {
  @Input() value: TValue = '';
  @Input() inputType: InputType = 'text';
  @Input() placeholder: string = '';
  @Input() control!: FormControl<TValue> | AbstractControl;
  @Input() mask!: string;
  @Input() isDirty: boolean = false;
  @Input() isInfo: boolean = false;
  @Input() isDisabled = false;
  @Input() openFocus: boolean = false;
  @Input() max: string = '';

  uniqueId = crypto.randomUUID();

  ngOnInit(): void {
    if (this.openFocus) {
      this.focus();
    }
  }

  writeValue(value: TValue): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onChange = (value: TValue) => {};

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onTouched = () => {};

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  focus() {
    setTimeout(() => {
      document.getElementById(this.uniqueId)?.focus();
    }, 50);
  }
}
